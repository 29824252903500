import { Component } from '@angular/core';

@Component({
  selector: 'chupito-icon',
  standalone: true,
  imports: [],
  templateUrl: './chupito.component.html',
  styleUrl: './chupito.component.scss'
})
export class ChupitoIcon {

}
