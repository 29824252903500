import { Component } from '@angular/core';

@Component({
  selector: 'ml50-icon',
  standalone: true,
  imports: [],
  templateUrl: './ml50.component.html',
  styleUrl: './ml50.component.scss'
})
export class Ml50Icon {

}
