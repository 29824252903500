import { Component } from '@angular/core';

@Component({
  selector: 'media-copa-icon',
  standalone: true,
  imports: [],
  templateUrl: './media-copa.component.html',
  styleUrl: './media-copa.component.scss'
})
export class MediaCopaIcon {

}
