import { Component } from '@angular/core';

@Component({
  selector: 'ml15-icon',
  standalone: true,
  imports: [],
  templateUrl: './ml15.component.html',
  styleUrl: './ml15.component.scss'
})
export class Ml15Icon{

}
