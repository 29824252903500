@switch (variant) {
    @case ('botella') {
        <botella-icon class="icon"></botella-icon>
    }
    @case ('copa') {
        <copa-icon class="icon"></copa-icon>
    }
    @case ('media-copa') {
        <media-copa-icon class="icon"></media-copa-icon>
    }
    @case ('chupito') {
        <chupito-icon class="icon"></chupito-icon>
    }
    @case ('doble-chupito') {
        <doble-chupito-icon class="icon"></doble-chupito-icon>
    }
    @case ('combinado') {
        <combinado-icon class="icon"></combinado-icon>
    }
    @case ('cocktail') {
        <cocktail-icon class="icon"></cocktail-icon>
    }
    @case ('15ml') {
        <ml15-icon class="icon"></ml15-icon>
    }
    @case ('30ml') {
        <ml30-icon class="icon"></ml30-icon>
    }
    @case ('40ml') {
        <ml40-icon class="icon"></ml40-icon>
    }
    @case ('50ml') {
        <ml50-icon class="icon"></ml50-icon>
    }
    @case ('60ml') {
        <ml60-icon class="icon"></ml60-icon>
    }
    @case ('300ml') {
        <ml300-icon class="icon"></ml300-icon>
    }
    @case ('720ml') {
        <ml720-icon class="icon"></ml720-icon>
    }
    @case ('1800ml') {
        <ml1800-icon class="icon"></ml1800-icon>
    }
    @default {
        <copa-icon class="icon"></copa-icon>
    }
}