import { Component } from '@angular/core';

@Component({
  selector: 'ml30-icon',
  standalone: true,
  imports: [],
  templateUrl: './ml30.component.html',
  styleUrl: './ml30.component.scss'
})
export class Ml30Icon {

}
