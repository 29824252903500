import { Component } from '@angular/core';

@Component({
  selector: 'ml60-icon',
  standalone: true,
  imports: [],
  templateUrl: './ml60.component.html',
  styleUrl: './ml60.component.scss'
})
export class Ml60Icon {

}
