import { Component } from '@angular/core';

@Component({
  selector: 'ml1800-icon',
  standalone: true,
  imports: [],
  templateUrl: './ml1800.component.html',
  styleUrl: './ml1800.component.scss'
})
export class Ml1800Icon {

}
