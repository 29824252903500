import { Component, Input } from '@angular/core';
import { BotellaIcon } from '../../icons/variants/botella/botella.component';
import { CopaIcon } from '../../icons/variants/copa/copa.component';
import { MediaCopaIcon } from '../../icons/variants/media-copa/media-copa.component';
import { ChupitoIcon } from '../../icons/variants/chupito/chupito.component';
import { CombinadoIcon } from '../../icons/variants/combinado/combinado.component';
import { DobleChupitoIcon } from '../../icons/variants/doble-chupito/doble-chupito.component';
import { Ml15Icon } from '../../icons/variants/ml15/ml15.component';
import { Ml30Icon } from '../../icons/variants/ml30/ml30.component';
import { Ml40Icon } from '../../icons/variants/ml40/ml40.component';
import { Ml50Icon } from '../../icons/variants/ml50/ml50.component';
import { Ml60Icon } from '../../icons/variants/ml60/ml60.component';
import { CocktailIcon } from '../../icons/variants/cocktail/cocktail.component';
import { Ml300Icon } from '../../icons/variants/ml300/ml300.component';
import { Ml720Icon } from '../../icons/variants/ml720/ml720.component';
import { Ml1800Icon } from '../../icons/variants/ml1800/ml1800.component';

const icons = [
  BotellaIcon,
  CopaIcon,
  MediaCopaIcon,
  ChupitoIcon,
  CombinadoIcon,
  DobleChupitoIcon,
  CocktailIcon,
  Ml15Icon,
  Ml30Icon,
  Ml40Icon,
  Ml50Icon,
  Ml60Icon,
  Ml300Icon,
  Ml720Icon,
  Ml1800Icon
];

@Component({
  selector: 'app-variant-icon',
  standalone: true,
  imports: [...icons],
  templateUrl: './variant-icon.component.html',
  styleUrl: './variant-icon.component.scss'
})
export class VariantIconComponent {

  @Input() variant?: string = '';

}
