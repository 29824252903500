import { Component, Input } from '@angular/core';
import { Price } from '../../../core/models/price.model';
import { VariantIconComponent } from '../variant-icon/variant-icon.component';
import { PriceVariantTranslationPipe } from '../../../core/pipes/priceVariantTranslation';

const components = [
  VariantIconComponent
]

const pipes = [
  PriceVariantTranslationPipe
]

@Component({
  selector: 'app-price',
  standalone: true,
  imports: [...components, ...pipes],
  templateUrl: './price.component.html',
  styleUrl: './price.component.scss'
})
export class PriceComponent {

  @Input() price!: Price;
  @Input() size: string = 'regular';
  @Input() currency: string = '€';
  @Input() menu: any;
  @Input() showName: boolean = false;

}
