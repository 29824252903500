import { Component } from '@angular/core';

@Component({
  selector: 'ml720-icon',
  standalone: true,
  imports: [],
  templateUrl: './ml720.component.html',
  styleUrl: './ml720.component.scss'
})
export class Ml720Icon {

}
