import { Component } from '@angular/core';

@Component({
  selector: 'doble-chupito-icon',
  standalone: true,
  imports: [],
  templateUrl: './doble-chupito.component.html',
  styleUrl: './doble-chupito.component.scss'
})
export class DobleChupitoIcon {

}
