import { Component } from '@angular/core';

@Component({
  selector: 'botella-icon',
  standalone: true,
  imports: [],
  templateUrl: './botella.component.html',
  styleUrl: './botella.component.scss'
})
export class BotellaIcon {

}
