import { Component } from '@angular/core';

@Component({
  selector: 'copa-icon',
  standalone: true,
  imports: [],
  templateUrl: './copa.component.html',
  styleUrl: './copa.component.scss'
})
export class CopaIcon {

}
