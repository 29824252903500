import { Component } from '@angular/core';

@Component({
  selector: 'combinado-icon',
  standalone: true,
  imports: [],
  templateUrl: './combinado.component.html',
  styleUrl: './combinado.component.scss'
})
export class CombinadoIcon {

}
