import { Component } from '@angular/core';

@Component({
  selector: 'cocktail-icon',
  standalone: true,
  imports: [],
  templateUrl: './cocktail.component.html',
  styleUrl: './cocktail.component.scss'
})
export class CocktailIcon {

}
