import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  standalone: true,
  name: 'priceVariantTranslation'
})
export class PriceVariantTranslationPipe implements PipeTransform {

    private translateService = inject(TranslateService)

    transform(menu: any | undefined, slugName: string): string {
        if (!menu)
            return '';

        const priceVariant = menu.priceVariantTranslations.find((x: any) => x.priceVariant.slugName == slugName);

        const priceVariantTranslation = priceVariant?.translations?.find((x: any) => x.locale.code == (this.translateService.currentLang ?? 'es'));
        if (priceVariantTranslation && priceVariantTranslation.name)
            return priceVariantTranslation.name;

        const catalogPriceVariant = priceVariant?.priceVariant;
        const catalogPriceVariantTranslation = catalogPriceVariant?.translations?.find((x: any) => x.locale.code == (this.translateService.currentLang ?? 'es'));
        if (catalogPriceVariantTranslation && catalogPriceVariantTranslation.name)
            return catalogPriceVariantTranslation.name;

        if (catalogPriceVariant)
            return catalogPriceVariant.name;


        const userPriceVariant = menu.user.activePriceVariants.find((x: any) => x.slugName == slugName);
        const userPriceVariantTranslation = userPriceVariant?.translations?.find((x: any) => x.locale.code == (this.translateService.currentLang ?? 'es'));
        if (userPriceVariantTranslation && userPriceVariantTranslation.name)
            return userPriceVariantTranslation.name;

        if (userPriceVariant)
            userPriceVariant.name;

        return '';
    }
}